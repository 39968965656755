var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"back-button",on:{"click":_vm.backToUpload}},[_vm._v("‹ zurück")]),_c('div',{staticClass:"preview-container is-justify-content-center mt-5"},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.uploadedImageUrl,"transitions":false,"stencil-component":"circle-stencil","stencil-props":{
        lines: {},
        handlers: {},
        resizable: false,
        aspectRatio: 1,
        scalable: false,
        movable: false
      },"stencil-size":_vm.stencilSize,"debounce":true,"canvas":{
        height: 200,
        width: 200
      },"image-restriction":"stencil","foreground-class":"cropper-background"},on:{"change":_vm.onChange}})],1),(_vm.$screen.breakpoint !== 'mobile')?_c('edit-image-navigation',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom}}):_vm._e(),_c('div',{staticClass:"field has-text-right mt-5"},[_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.cropImage}},[_vm._v("Speichern")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }