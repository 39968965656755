export interface IGuest {
  id?: number
  uuid: string
  vorname: string
  email: string
  status?: EStatus
  gastgruppe?: EGastgruppe
  foto?: {
    url?: string
  } | null
  eingeladen_von?: string
  erstell_datum: Date
  letzter_besuch?: Date
}

export enum EGastgruppe {
  STAMMGAST = 'stammgast',
  VIP = 'vip',
  MITARBEITER = 'mitarbeiter',
  FAMILY = 'family'
}

export enum EStatus {
  AKTIV = 'aktiv',
  WARTEN_AUF_FREIGABE = 'warten_auf_freigabe'
}
