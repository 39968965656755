

















import { Component, Vue } from 'vue-property-decorator'
import NewGuestForm from '@/components/register/NewGuestForm.vue'
import PageLayout from '@/components/shared/PageLayout.vue'

@Component({ components: { PageLayout, NewGuestForm } })
export default class Register extends Vue {
  private newGuestFormSubmitted = false

  private closeCompleteText() {
    this.newGuestFormSubmitted = false
  }
}
