

















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'

@Component({ components: { Cropper, CircleStencil } })
export default class EditImageNavigation extends Vue {
  @Prop({ type: Number, default: 0 }) private zoom!: number

  private focus = false

  $refs!: {
    line: any
  }

  private mounted() {
    window.addEventListener('mouseup', this.onStop, { passive: false })
    window.addEventListener('mousemove', this.onDrag, { passive: false })
    window.addEventListener('touchmove', this.onDrag, { passive: false })
    window.addEventListener('touchend', this.onStop, { passive: false })
  }

  private destroyed() {
    window.removeEventListener('mouseup', this.onStop)
    window.removeEventListener('mousemove', this.onDrag)
    window.removeEventListener('touchmove', this.onDrag)
    window.removeEventListener('touchend', this.onStop)
  }

  private onDrag(e: any) {
    if (this.focus) {
      const position = e.touches ? e.touches[0].clientX : e.clientX
      const line = this.$refs.line

      if (line) {
        const { left, width } = line.getBoundingClientRect()
        this.$emit('change', Math.min(1, Math.max(0, position - left) / width))
      }
      if (e.preventDefault) {
        e.preventDefault()
      }
    }
  }
  private onStop() {
    this.focus = false
  }
  private onStart(e: Event) {
    this.focus = true
    this.onDrag(e)
  }
}
