



















import { Component, Vue } from 'vue-property-decorator'
import FacebookIcon from '@/components/static/FacebookIcon.vue'
import InstagramIcon from '@/components/static/InstagramIcon.vue'
import { LINKS } from '@/services/util/helper/constants'

@Component({ components: { FacebookIcon, InstagramIcon } })
export default class PageFooter extends Vue {
  private LINKS = LINKS

  private get recentYear() {
    // return new Date().getFullYear()
    return '2021'
  }
}
