import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store, { isModuleInLs } from '@/store'

const MODULE = 'notification'

@Module({ dynamic: true, store: store, name: MODULE, preserveState: isModuleInLs(MODULE) })
class NotificationStore extends VuexModule {
  notifications: string[] = []

  @Action({ rawError: true })
  addNotification(notification: string) {
    this.ADD_NOTIFICATION(notification)
    setTimeout(() => {
      this.removeNotification(notification)
    }, 5000)
  }

  @Action({ rawError: true })
  removeNotification(notification: string) {
    this.REMOVE_NOTIFICATION(notification)
  }

  @Mutation
  ADD_NOTIFICATION(notification: string) {
    this.notifications.push(notification)
  }

  @Mutation
  REMOVE_NOTIFICATION(notification: string) {
    const index = this.notifications.indexOf(notification)
    if (this.notifications.length > 0) {
      this.notifications.splice(index, 1)
    }
  }
}

export const NotificationModule = getModule(NotificationStore)
