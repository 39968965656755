import { IGuest } from '../models/Guest'
import { apiBaseUrl } from './ApiConfig'

export class GuestsApi {
  static async fetchAllGuests(): Promise<IGuest[]> {
    return apiBaseUrl.get('/guests').then(response => {
      return response.data
    })
  }

  static async fetchGuest(guestId: string): Promise<IGuest> {
    return apiBaseUrl.get('/guests?uuid=' + guestId).then(response => {
      return response.data[0]
    })
  }

  static async updateGuest(guestId: number, guest: IGuest): Promise<IGuest> {
    return apiBaseUrl.put('/guests/' + guestId.toString(), guest).then(response => {
      return response.data
    })
  }

  static async deleteGuest(guestId: number): Promise<IGuest> {
    return apiBaseUrl.delete('/guests/' + guestId.toString()).then(response => {
      return response.data
    })
  }

  static async uploadGuestImage(fileData: FormData): Promise<unknown> {
    return apiBaseUrl.post('/upload', fileData).then(response => {
      return response.data
    })
  }

  static async findOneGuest(guestId: number): Promise<unknown> {
    return apiBaseUrl.get('/guests/' + guestId.toString()).then(response => {
      return response.data
    })
  }
}
