























import { Component, Vue } from 'vue-property-decorator'
import { RegisterApi } from '@/services/api/RegisterApi'
import { EGastgruppe, EStatus, IGuest } from '@/services/models/Guest'
import { FormValidationUtil } from '@/services/util/FormValidationUtil'
import { UserModule } from '@/store/modules/User'
import { NotificationUtil } from '@/services/util/NotificationUtil'
import { NOTIFICATION_TEXTS, VALIDATION_MESSAGES } from '@/services/util/helper/constants'

@Component
export default class NewGuestForm extends Vue {
  private newGuest = {} as IGuest
  private error = ''

  private registerNewGuest(e: Event) {
    e.preventDefault()
    if (!this.validEmail(this.newGuest.email)) {
      this.error = VALIDATION_MESSAGES.isNotEmail
    } else {
      this.error = ''
      this.newGuest.eingeladen_von = UserModule.currentUser.username || ''
      this.newGuest.erstell_datum = new Date()
      this.newGuest.status = EStatus.WARTEN_AUF_FREIGABE
      this.newGuest.gastgruppe = EGastgruppe.STAMMGAST
      RegisterApi.createNewRegistration(this.newGuest)
        .then(() => {
          this.$emit('complete', true)
        })
        .catch(error => {
          let errorMsg = NOTIFICATION_TEXTS.guest.create.error
          if (error.response.data === 'ER_DUP_ENTRY') {
            errorMsg = NOTIFICATION_TEXTS.guest.create.duplicate
          }
          NotificationUtil.add(errorMsg)
        })
    }
  }

  private validEmail(email: string) {
    return FormValidationUtil.validEmail(email)
  }
}
