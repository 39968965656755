<template>
  <page-layout>
    <template #content>
      <loading-spinner :loading="isLoading" />
      <div>
        <div>
          <div>
            <vue-web-cam
              ref="webcam"
              :device-id="deviceId"
              width="100%"
              @started="onStarted"
              @stopped="onStopped"
              @error="onError"
              @cameras="onCameras"
              @camera-change="onCameraChange"
            />
          </div>
          <div class="field is-grouped mt-2 is-grouped-right">
            <p class="control">
              <button class="button is-secondary" @click="cancel()">Abbrechen</button>
            </p>
            <p class="control">
              <button class="button is-primary" @click="onCapture">Aufnehmen</button>
            </p>
          </div>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { WebCam } from 'vue-web-cam'
import PageLayout from '@/components/shared/PageLayout.vue'
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'

export default {
  name: 'CaptureImage',
  components: {
    'vue-web-cam': WebCam,
    PageLayout,
    LoadingSpinner
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      isLoading: false
    }
  },
  created() {
    this.isLoading = true
  },
  computed: {
    device: function () {
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id
    },
    devices: function () {
      // Once we have a list select the first one
      const [first] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    }
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture()
      var block = this.img.split(';')
      var realData = block[1].split(',')[1]
      var contentType = block[0].split(':')[1]
      var blob = this.b64toBlob(realData, contentType)
      this.$emit('change', blob)
    },
    onStarted() {
      this.isLoading = false
      // console.log('On Started Event', stream)
    },
    onStopped() {
      // console.log('On Stopped Event', stream)
      console.log('On Stopped Event')
    },
    onStop() {
      this.$refs.webcam.stop()
    },
    onStart() {
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log('On Error Event', error)
    },
    onCameras(cameras) {
      this.devices = cameras
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || ''
      sliceSize = sliceSize || 512

      var byteCharacters = atob(b64Data)
      var byteArrays = []

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize)

        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      var blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
