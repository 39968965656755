


































































import { Component, Vue } from 'vue-property-decorator'
import CaptureImage from '@/components/register/CaptureImage.vue'
import EditImageForm from '@/components/register/EditImageForm.vue'
import { GuestsApi } from '@/services/api/GuestsApi'
import { NotificationUtil } from '@/services/util/NotificationUtil'
import { IGuest } from '@/services/models/Guest'
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'
import { NOTIFICATION_TEXTS } from '@/services/util/helper/constants'

@Component({ components: { CaptureImage, EditImageForm, LoadingSpinner } })
export default class UploadImageForm extends Vue {
  private imageFile = new File([''], this.guestId)
  private uploadedImageUrl: string | null = null
  private completed = false
  private isTakePhoto = false
  private guestData = {} as IGuest
  private isLoading = false

  private async created() {
    this.isLoading = true
    this.guestData = await GuestsApi.fetchGuest(this.guestId)
    if (!this.guestData) {
      NotificationUtil.add(NOTIFICATION_TEXTS.guest.read.error)
    }
    if (this.guestData?.foto) {
      this.navigateToCompleteRegister()
    }
    this.isLoading = false
  }

  private get guestId() {
    return this.$route?.params?.id ?? ''
  }

  private get hasGuestData() {
    return this.guestData !== undefined
  }

  private async updateNewGuestImage() {
    try {
      let formData = new FormData()
      formData.append('ref', 'guest')
      formData.append('refId', this.guestData?.id?.toString() || '')
      formData.append('files', this.uploadedImageFile, this.guestId)
      formData.append('field', 'foto')
      await GuestsApi.uploadGuestImage(formData)
      await GuestsApi.findOneGuest(this.guestData.id as number)
      this.navigateToCompleteRegister()
    } catch (error) {
      NotificationUtil.add(NOTIFICATION_TEXTS.guest.uploadImage.error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showMyImage(e: any) {
    this.completed = false
    const file = e.target.files[0]
    this.uploadedImageUrl = URL.createObjectURL(file)
    this.uploadedImageFile = file
  }

  get uploadedImageFile(): File {
    return this.imageFile
  }

  set uploadedImageFile(image: File) {
    this.imageFile = image
  }

  private deleteUploadImage() {
    this.uploadedImageUrl = null
    this.imageFile = new File([''], '')
  }

  private editedImageFile(event: File) {
    this.uploadedImageUrl = URL.createObjectURL(event)
    this.uploadedImageFile = event
  }

  private capturedImageFile(event: File) {
    this.uploadedImageUrl = URL.createObjectURL(event)
    this.uploadedImageFile = event
    this.isTakePhoto = false
  }

  private completedEdit(e: boolean) {
    this.completed = e
  }

  private cancelEdit() {
    this.deleteUploadImage()
  }

  private navigateToCompleteRegister() {
    this.$router.push({ name: 'uploadImageComplete' }).catch(error => console.log(error))
  }

  private takePhoto() {
    this.imageFile = new File([''], this.guestId)
    this.completed = false
    this.isTakePhoto = true
  }

  private cancelCapturing() {
    this.completed = true
    this.isTakePhoto = false
  }
}
