export const VALIDATION_MESSAGES = {
  isNotEmail: 'Bitte eine gültige E-Mail Adresse eingeben.'
}

export const NOTIFICATION_TEXTS = {
  login: {
    hello: 'Hallo ${userRole}',
    error: 'Fehler beim Login',
    hasNoPermissions: 'Leider hast du keine Berechtigung',
    hasToLogin: 'Keine Berechtigung, Bitte anmelden'
  },
  guest: {
    create: {
      error: 'Der neue Gast kann nicht gespeichert werden',
      duplicate: 'Diese E-Mail Adresse existiert schon'
    },
    read: {
      success: '',
      error: 'Dieser Gast existiert nicht oder kann nicht geladen werden',
      noData: 'Keine Daten verfügbar für diese ID'
    },
    update: {
      success: 'Gast erfolgreich aktualisiert',
      error: 'Gast konnte nicht aktualisiert werden'
    },
    delete: {
      success: 'Gast erfolgreich gelöscht',
      error: 'Gast konnte nicht gelöscht werden'
    },
    list: {
      error: 'Die Daten der Tabelle konnten nicht geladen werden'
    },
    uploadImage: {
      error: 'Das Foto konnte nicht hochgeladen werden'
    }
  }
}

export const LINKS = {
  logo: {
    title: 'Bar Zum schmutzigen Hobby',
    href: 'https://zumschmutzigenhobby.de/'
  },
  impressum: {
    title: 'Impressum',
    href: 'https://zumschmutzigenhobby.de/impressum.html'
  },
  datenschutz: {
    title: 'Datenschutzerklärung',
    href: 'https://zumschmutzigenhobby.de/datenschutz.html'
  },
  facebook: {
    title: 'Facebook Bar Zum Schmutzigen Hobby',
    href: 'https://www.facebook.com/zumschmutzigenhobby'
  },
  instagram: {
    title: 'Instagram Bar Zum schmutzigen Hobby',
    href: 'https://www.instagram.com/zumschmutzigenhobby/'
  }
}

export const USER_ROLES = {
  admin: 'Admin',
  bar: 'Bar',
  security: 'Security'
}
