/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'

import { UserModule } from '@/store/modules/User'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
} as any
axios.defaults.headers = headers

export const apiBaseUrl = axios.create({ headers })

apiBaseUrl.defaults.baseURL = process.env.VUE_APP_API_URL

// Request interceptors
// Set Authorization Token except for login
apiBaseUrl.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (UserModule.currentUserToken && config.url !== '/auth/local') {
      config.headers = {
        Authorization: `Bearer ${UserModule.currentUserToken}`
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
