
































import { Component, Vue } from 'vue-property-decorator'
import { ILoginRequest, IUser } from '@/services/models/User'
import PageLayout from '@/components/shared/PageLayout.vue'
import { LoginApi } from '@/services/api/LoginApi'
import { FormValidationUtil } from '@/services/util/FormValidationUtil'
import { UserModule } from '@/store/modules/User'
import { PermissionModule } from '@/store/modules/Permission'
import { NotificationUtil } from '@/services/util/NotificationUtil'
import { NOTIFICATION_TEXTS, USER_ROLES, VALIDATION_MESSAGES } from '@/services/util/helper/constants'

@Component({ components: { PageLayout } })
export default class Login extends Vue {
  private user = {} as ILoginRequest
  private error = ''
  private passwordIcon = 'eye'
  private passwordType = 'password'
  private isLoading = false

  private async login() {
    if (!this.validEmail(this.user.identifier)) {
      this.error = VALIDATION_MESSAGES.isNotEmail
    } else {
      this.error = ''
      try {
        this.isLoading = true
        const res = await LoginApi.userLogin(this.user)
        const { jwt, user } = res
        UserModule.setCurrentUser(user)
        UserModule.setCurrentUserToken(jwt)
        PermissionModule.setPermissions(user)
        this.navigateToRoute(user)
      } catch (error) {
        NotificationUtil.add(NOTIFICATION_TEXTS.login.error)
      } finally {
        this.isLoading = false
      }
    }
  }
  // Navigate to route based on user permissions
  private navigateToRoute(user: IUser) {
    const toRoute = user.role.type === 'admin' ? '/guest/list' : '/register'
    this.$router
      .push({ path: this.$route.params.to || toRoute })
      .then(() => {
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hello.replace('${userRole}', this.formattedRoleName(user.role.name)))
      })
      .catch(() => {
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasNoPermissions)
      })
  }

  private togglePassword() {
    this.passwordIcon = this.passwordIcon === 'eye' ? 'eye-slash' : 'eye'
    this.passwordType = this.passwordIcon === 'eye-slash' ? 'text' : 'password'
  }

  private validEmail(email: string) {
    return FormValidationUtil.validEmail(email)
  }

  private formattedRoleName(role: string) {
    if (role === USER_ROLES.bar) {
      return 'Bar'
    }

    if (role === USER_ROLES.security) {
      return 'Security'
    }
    return role
  }
}
