






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
}
