import {
  faArrowCircleRight,
  faCamera,
  faChevronCircleDown,
  faChevronCircleUp,
  faEye,
  faEyeSlash,
  faTrashAlt,
  faUpload
} from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import VueScreen from 'vue-screen'
import { library } from '@fortawesome/fontawesome-svg-core'
import router from './router'
import store from './store'

Vue.config.productionTip = false
library.add(faUpload)
library.add(faCamera)
library.add(faTrashAlt)
library.add(faChevronCircleUp)
library.add(faChevronCircleDown)
library.add(faArrowCircleRight)
library.add(faEye)
library.add(faEyeSlash)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueScreen, 'bulma')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
