import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store, { isModuleInLs } from '@/store'

import { IUser } from '@/services/models/User'
import { USER_ROLES } from '@/services/util/helper/constants'

const MODULE = 'permission'

@Module({ dynamic: true, store: store, name: MODULE, preserveState: isModuleInLs(MODULE) })
class PermissionStore extends VuexModule {
  userPermissions = {
    hasRegisterRight: false,
    hasGuestListRight: false,
    hasGuestDetailReadRight: false,
    hasGuestDetailUpdateRight: false
  }

  get hasRegisterRight() {
    return this.userPermissions.hasRegisterRight
  }

  get hasGuestListRight() {
    return this.userPermissions.hasGuestListRight
  }

  get hasGuestDetailReadRight() {
    return this.userPermissions.hasGuestDetailReadRight
  }

  get hasGuestDetailUpdateRight() {
    return this.userPermissions.hasGuestDetailUpdateRight
  }

  @Action({ rawError: true })
  setPermissions(currentUser: IUser) {
    this.SET_PERMISSIONS(currentUser)
  }

  @Mutation
  SET_PERMISSIONS(currentUser: IUser) {
    const userRole = currentUser?.role?.name ?? ''
    this.userPermissions.hasRegisterRight = userRole === USER_ROLES.bar || userRole === USER_ROLES.admin
    this.userPermissions.hasGuestListRight = userRole === USER_ROLES.admin
    this.userPermissions.hasGuestDetailReadRight =
      userRole === USER_ROLES.bar || userRole === USER_ROLES.security || userRole === USER_ROLES.admin
    this.userPermissions.hasGuestDetailUpdateRight = userRole === USER_ROLES.admin
  }
}

export const PermissionModule = getModule(PermissionStore)
