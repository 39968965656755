import { IGuest } from '../models/Guest'
import { apiBaseUrl } from './ApiConfig'

export class RegisterApi {
  static async createNewRegistration(guest: IGuest): Promise<IGuest> {
    return apiBaseUrl.post('/guests', guest).then(response => {
      return response.data
    })
  }
}
