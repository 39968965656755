import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import store, { isModuleInLs } from '@/store'

import { IUser } from '@/services/models/User'

const MODULE = 'user'

@Module({ dynamic: true, store: store, name: MODULE, preserveState: isModuleInLs(MODULE) })
class UserStore extends VuexModule {
  currentUser = {} as IUser
  currentUserToken = ''

  @Action
  setCurrentUser(currentUser: IUser) {
    this.SET_CURRENT_USER(currentUser)
  }

  @Action
  setCurrentUserToken(currentUserToken: string) {
    this.SET_CURRENT_USER_TOKEN(currentUserToken)
  }

  @Mutation
  SET_CURRENT_USER(currentUser: IUser) {
    this.currentUser = currentUser
  }

  @Mutation
  SET_CURRENT_USER_TOKEN(currentUserToken: string) {
    this.currentUserToken = currentUserToken
  }
}

export const UserModule = getModule(UserStore)
