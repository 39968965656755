


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue'

export const CONTENT_WIDTH = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}
type contentWidthKeys = keyof typeof CONTENT_WIDTH
export type contentWidth = typeof CONTENT_WIDTH[contentWidthKeys]

@Component({ components: { LoadingSpinner } })
export default class PageLayout extends Vue {
  @Prop({
    type: String as PropType<contentWidth>,
    default: CONTENT_WIDTH.MEDIUM,
    validator(value: contentWidth) {
      return value in CONTENT_WIDTH || Object.values(CONTENT_WIDTH).includes(value)
    }
  })
  readonly contentWidth!: string

  @Prop({ type: Boolean, default: false }) readonly isLoading!: boolean

  private get contentClasses() {
    const classes = []
    if (this.contentWidth) {
      classes.push(`content-size_${this.contentWidth}`)
    }
    return classes
  }
}
