








import { LINKS } from '@/services/util/helper/constants'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  private LINKS = LINKS
}
