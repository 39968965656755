






































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import EditImageNavigation from './EditImageNavigation.vue'

@Component({ components: { Cropper, CircleStencil, EditImageNavigation } })
export default class EditImageForm extends Vue {
  @Prop() private imageFile!: File

  private zoom = 0

  $refs!: {
    cropper: Cropper | any
  }

  private cropImage() {
    const { canvas } = this.$refs.cropper.getResult()
    if (canvas) {
      canvas?.toBlob((blob: any) => {
        this.$emit('change', blob)
        this.$emit('complete', true)
      })
    }
  }

  private stencilSize({ boundaries }: any) {
    return {
      width: Math.min(boundaries.height, boundaries.width),
      height: Math.min(boundaries.height, boundaries.width)
    }
  }

  get uploadedImageUrl(): string {
    return URL.createObjectURL(this.editedImageFile)
  }

  get editedImageFile(): File {
    return this.imageFile
  }

  private backToUpload() {
    this.$emit('complete', true)
    this.$emit('cancel')
  }

  private onZoom(value: any) {
    const cropper = this.$refs.cropper
    if (cropper) {
      if (cropper.imageSize.height < cropper.imageSize.width) {
        const minHeight = cropper.sizeRestrictions.minHeight
        const imageHeight = cropper.imageSize.height
        cropper.zoom((imageHeight - this.zoom * (imageHeight - minHeight)) / (imageHeight - value * (imageHeight - minHeight)))
      } else {
        const minWidth = cropper.sizeRestrictions.minWidth
        const imageWidth = cropper.imageSize.width
        cropper.zoom((imageWidth - this.zoom * (imageWidth - minWidth)) / (imageWidth - value * (imageWidth - minWidth)))
      }
    }
  }

  private onChange() {
    const cropper = this.$refs.cropper
    if (cropper) {
      const { coordinates, imageSize } = cropper
      if (imageSize.width / imageSize.height > coordinates.width / coordinates.height) {
        this.zoom =
          (cropper.imageSize.height - cropper.coordinates.height) / (cropper.imageSize.height - cropper.sizeRestrictions.minHeight)
      } else {
        this.zoom = (cropper.imageSize.width - cropper.coordinates.width) / (cropper.imageSize.width - cropper.sizeRestrictions.minWidth)
      }
    }
  }
}
