import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
  modules: ['user', 'permission']
})

export default new Vuex.Store({ plugins: [vuexPersist.plugin] })

function isModuleInLs(module: string): boolean {
  const ls = localStorage.getItem('vuex')
  return ls ? module in JSON.parse(ls) : false
}

export { isModuleInLs }
