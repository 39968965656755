import VueRouter, { RouteConfig } from 'vue-router'

import Login from '../components/login/Login.vue'
import { LoginApi } from '@/services/api/LoginApi'
import { NOTIFICATION_TEXTS } from '@/services/util/helper/constants'
import { NotificationUtil } from '@/services/util/NotificationUtil'
import { PermissionModule } from '@/store/modules/Permission'
import Register from '../components/register/Register.vue'
import UploadImage from '../components/register/UploadImage.vue'
import Vue from 'vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (PermissionModule.hasRegisterRight) {
        next()
      } else {
        next({ name: 'login', params: { to: to.path ?? '' } })
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasToLogin)
      }
    }
  },
  {
    path: '/register/upload-image/:id',
    name: 'registerUploadImage',
    component: UploadImage
  },
  {
    path: '/register/complete',
    name: 'uploadImageComplete',
    component: () => import(/* webpackChunkName: "register/complete" */ '../components/register/UploadImageComplete.vue')
  },
  {
    path: '/guest/list',
    name: 'guestsList',
    meta: {
      header: false,
      footer: false
    },
    component: () => import(/* webpackChunkName: "guest/list" */ '../components/guest/list/GuestsList.vue'),
    beforeEnter: (to, from, next) => {
      if (PermissionModule.hasGuestListRight) {
        next()
      } else {
        next({ name: 'login', params: { to: to.path ?? '' } })
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasToLogin)
      }
    }
  },
  {
    path: '/guest/detail/:id',
    name: 'guestDetail',
    meta: {
      header: true
    },
    component: () => import(/* webpackChunkName: "guest/detail" */ '../components/guest/detail/GuestDetail.vue'),
    beforeEnter: (to, from, next) => {
      if (PermissionModule.hasGuestDetailUpdateRight) {
        next()
      } else {
        next({ name: 'login', params: { to: to.path ?? '' } })
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasToLogin)
      }
    }
  },
  {
    path: '/guest/infos/:id',
    name: 'guestShortInfos',
    meta: {
      header: true,
      footer: true
    },
    component: () => import(/* webpackChunkName: "guest/infos" */ '../components/guest/detail/GuestShortInfos.vue'),
    beforeEnter: (to, from, next) => {
      if (PermissionModule.hasGuestDetailReadRight) {
        next()
      } else {
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasToLogin)
        next({ name: 'login', params: { to: to.path ?? '' } })
      }
    }
  },
  {
    path: '/error/:code',
    name: 'errorPage',
    meta: {
      header: true,
      footer: true
    },
    component: () => import(/* webpackChunkName: "pages/error" */ '../pages/ErrorPage.vue')
  },
  {
    path: '*',
    redirect: 'error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'registerUploadImage' && to.name !== 'uploadImageComplete') {
    LoginApi.userMe()
      .then(() => {
        next()
      })
      .catch(() => {
        next({ name: 'login', params: { to: to.path ?? '' } })
        NotificationUtil.add(NOTIFICATION_TEXTS.login.hasToLogin)
      })
  } else {
    next()
  }
})

export default router
