import { ILoginRequest, ILoginResponse } from '../models/User'

import { apiBaseUrl } from './ApiConfig'

export class LoginApi {
  static async userLogin(userLoginData: ILoginRequest): Promise<ILoginResponse> {
    return apiBaseUrl.post('/auth/local', userLoginData).then(response => {
      return response.data
    }) as Promise<ILoginResponse>
  }

  static async userMe(): Promise<ILoginResponse> {
    return apiBaseUrl.get('/users/me').then(response => {
      return response.data
    }) as Promise<ILoginResponse>
  }
}
