













import { Component, Vue } from 'vue-property-decorator'
import PageFooter from '@/components/footer/PageFooter.vue'
import PageHeader from '@/components/header/PageHeader.vue'
import Notification from '@/components/shared/Notification.vue'

@Component({ components: { PageHeader, PageFooter, Notification } })
export default class App extends Vue {}
