








import { NotificationModule } from '@/store/modules/Notification'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Notification extends Vue {
  private get notifications() {
    return NotificationModule.notifications
  }
}
