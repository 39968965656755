












import { Component, Vue } from 'vue-property-decorator'
import UploadImageForm from '@/components/register/UploadImageForm.vue'
import PageLayout from '@/components/shared/PageLayout.vue'
import { UserModule } from '@/store/modules/User'
import { PermissionModule } from '@/store/modules/Permission'
import { IUser } from '@/services/models/User'

@Component({ components: { PageLayout, UploadImageForm } })
export default class UploadImage extends Vue {
  private created() {
    // reset user and token for public use
    UserModule.setCurrentUser({} as IUser)
    UserModule.setCurrentUserToken('')
    PermissionModule.setPermissions({} as IUser)
  }
}
